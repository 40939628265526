import '../styles/index.scss';
import ScrollReveal from 'scrollreveal';

const hamburger = document.querySelector('.navbar-toggler');
const menu = document.querySelector('.navbar-collapse');
hamburger.addEventListener('click', () => {
	menu.classList.toggle('show');
});

const options = {
	delay: 200,
	distance: '50px',
	origin: 'left',
	duration: 1000
};

ScrollReveal().reveal('.reveal', options);
ScrollReveal().reveal('.train', {
	delay: 200,
	distance: '250px',
	origin: 'left',
	duration: 2000
});